@import "variables";

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: $background-color;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.card {
  border: none; // 1px solid lightgray;
  background-color: white;
  padding: 24px;

  &.c-shadow {
    box-shadow: 3px 3px 9px #d3d3d3;
  }
  &.bg-color {
    background: #f5f5f5;
  }
  .card-bottom {
    border-top: 2px solid $background-color;
    padding: 15px;
    height: 70px;
    z-index: 10;
  }
  .card-body {
    margin-top: 15px;
    border-top: 2px solid $background-color;
    padding-bottom: 0;
  }
  .card-title {
    font-size: 20px;
    margin-bottom: 5px;
    color: $Gray-800;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    // padding: 15px;
    padding-bottom: 0;
  }
  .card-subtitle {
    padding: 15px;
    padding-top: 5px;
    padding-bottom: 0;
    color: $Gray-600;
    font-size: 14px;
    margin-bottom: 0 !important;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    &.icon {
      display: flex;
      align-items: initial;

      .mat-icon {
        font-size: 16px;
        margin-right: 0;
      }
    }
  }
}

.main-content {
  margin-top: -96px;
}
.app-button {
  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: #000000;
  }
}

.btn {
  border-radius: $BorderRadius;

  &.btn-primary {
    background-color: $TertiaryColor;
    border-color: $TertiaryColor;
  }

  &.btn-light {
    color: gray;
  }

  &.btn-link {
    &:hover {
      text-decoration: none;
    }
  }
}
a{
  &.no-decoration{
    text-decoration: none;

  }
}
app-toolbar-options {
  .navbar {
    width: 100% !important;
  }
  .bg-dark {
    background-color: transparent !important;
    margin-bottom: 32px;

    .card {
      padding: 8px;
      border-radius: 8px;
      box-shadow: 1px 1px 2px chocolate !important;
    }

    .page-title {
      color: white; // var(--SecondaryColor);
      margin-left: 18px;
    }
  }
}

@-webkit-keyframes fadeInUpSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50px, 0);
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUpSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50px, 0);
    transform: translate3d(0, 50px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.animate__fadeInUpSmall {
  -webkit-animation-name: fadeInUpSmall;
  animation-name: fadeInUpSmall;
}

.page-content-actions {
  padding: 24px;
}

// .page-component {
//   .header {
//     .title {
//       margin-bottom: 8px !important;
//       position: absolute;
//     }
//   }
// }
#main-layout-container {
  .grecaptcha-badge {
    display: none !important;
  }
}
app-scroll-up {
  z-index: 1;
}
app-checkbox {
  display: flex;
}
app-copyable {
  .app-copyable {
    color: #b5b5c3;
  }
}
app-component-wrapper {
  &.mt {
    margin-top: 32px;
  }
}

::ng-deep app-entry-address-accurity {
  h5 {
    font-family: "DM Sans" !important;
    font-size: 12px !important;
    font-weight: 600 !important;
  }
  mat-radio-group {
    // position: absolute;
  }
}


.click {
  cursor: pointer;
}

app-toolbar-options {
  .page-title {
    font-size: 25px;
  }
  .card {
    background-color: transparent;

    .mat-icon {
      color: white;
    }
  }
}
button {
  &:focus {
    outline: none;
  }
}
.badge {
  &.badge-light-success {
    color: #50cd89;
    background-color: #e8fff3;
  }
  &.badge-light-danger {
    color: tomato;
    background-color: lighten($color: tomato, $amount: 30%);
  }
  &.badge-light-primary {
    color: #009ef7;
    background-color: #f1faff;
  }
}

.table {
  td {
    color: rgb(126, 130, 153);
  }
  thead {
    th {
      color: rgb(161, 165, 183);
      font-weight: 500;
      border-top: none;
    }
  }
}

.mat-form-field .mat-icon.invalid {
  color: #d3d3d3 !important;
}



.mat-mdc-form-field{
  padding-top: 6px;
}
