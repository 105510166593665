@use "@angular/material" as mat;


$prefix: "--"; // Prefix string for custom CSS properties


$custom-typography: mat.define-typography-config(
  $font-family: "inherit"
);
// @include mat-base-typography($custom-typography);
// @include mat-checkbox-typography($custom-typography);
// @include angular-material-typography($custom-typography);
// @include mat-core($custom-typography);
@include mat.core();
@include mat.typography-hierarchy($custom-typography);


// Merges a variable name with $prefix
@function custom-property-name($name) {
    @return $prefix + $name;
}

// Defines a custom property
@mixin define-custom-property($name, $value) {
    #{custom-property-name($name)}: $value;
}

$BorderRadius: 8px;
$PrimaryColor: #2e2e2e;
$PrimaryDarkColor: darken($PrimaryColor, 5%);
$PrimaryDarkenColor: darken($PrimaryColor, 10%);
$PrimaryLighterColor: lighten($PrimaryColor, 10%);
$PrimaryGradient: $PrimaryColor;
$PrimaryLight: lighten($PrimaryColor, 5%);;
$SecondaryColor: #DB1E33;
$SecondaryDarkColor: darken($SecondaryColor, 10%);
$SecondaryLighterColor: lighten($SecondaryColor, 10%);
$SecondaryGradient: $SecondaryColor;
$TertiaryColor: #374B58;
$Green: #368B85;
$Red: #ff4a4a;
$Orange: #ff844a;
$Blue: #4acaff;
$HyperLink: #567cd7;

$Gray-100: #f6f7f8;
$Gray-200: #ebecef;
$Gray-300: #ced2d9;
$Gray-400: #b2b8c2;
$Gray-500: #959eac;
$Gray-600: #788396;
$Gray-700: #606a7b;
$Gray-800: #4a515e;
$Gray-900: #333942;
$Gray-Black: #000000;
$Gray-White: #ffffff;
$Gray-Bg: #ffffff;
$GrayShadowColor: #ffffff;
$GrayShadowBackgroundColor: #f6f7f8;

:root {
  --PrimaryColor: #{$PrimaryColor};
  --SecondaryColor: #{$SecondaryColor};

}


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// @include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$portal-primary: mat.define-palette(mat.$indigo-palette);
$portal-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$portal-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$portal-theme: mat.define-light-theme(
  (
    color: (
      primary: $portal-primary,
      accent: $portal-accent,
      warn: $portal-warn,
    ),
    typography: $custom-typography

  )
);
$background-color: #f1f4f6;
@include mat.all-component-themes($portal-theme);
